<template>
    <div>
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CAlert show color="primary">
                    <h4 class="alert-heading"><font-awesome-icon icon="signature"/> {{ $route.query.name }}</h4>
                    <hr>
                    <p class="mb-0">
                        Has {{ signed_doc_num }} document(s) signed and belong to {{number_of_templates_belong}} document template(s).
                    </p>
                </CAlert>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader>
                        <font-awesome-icon icon="paperclip"/>  User Templates
                    </CCardHeader>
                    <CCardBody>
                        
                        <CTabs :active-tab="active_tab" @update:activeTab="changeTab">
                            <br>
                            <CTab>
                                <template slot="title">
                                    Active Template/s <CBadge color="success">( {{ [...new Set(active_templates.map((item) => item.name))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="active_templates"
                                            :fields="templates_field"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            @row-clicked="rowClicked"
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            
                                            <template #checkbox-header>
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success" v-model="isCheckedAll"
                                                    @change="selectAllActive($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>
                                    
                                            <template #checkbox="{item}">
                                                <td>
                                                    <center>
                                                        <p-check 
                                                            class="p-icon p-bigger" 
                                                            color="success" 
                                                            :value="item.id"
                                                            v-model="selected_templates"
                                                        >
                                                            <font-awesome-icon class="icon" icon="check"/>
                                                            
                                                        </p-check>
                                                        &nbsp; 
                                                    </center>
                                                </td>
                                            </template>
                                            
                                            <template #requirements="{item}">
                                                <td>
                                                    <CButton 
                                                        color="info" 
                                                        shape="pill" 
                                                        size="sm"
                                                        @click="showMyRequirements(item)"
                                                    >
                                                        <font-awesome-icon icon="eye"/> Requirements ( {{ item.requirements.length }} )
                                                    </CButton>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton 
                                            block
                                            size="sm"
                                            color="dark" 
                                            @click="openRemoveRequirementsModal()"
                                            :disabled="action_control"
                                            v-if="$store.getters['can']('remove-document-template-signatory-requirements')"
                                        >
                                            <font-awesome-icon icon="times"/> Remove Requirements
                                        </CButton>
                                    </CCol>
                                    <CCol lg="3">
                                        <CButton 
                                            block
                                            size="sm"
                                            color="info" 
                                            @click="openAddRequirementsModal()"
                                            :disabled="action_control"
                                            v-if="$store.getters['can']('add-document-template-signatory-requirements')"
                                        >
                                            <font-awesome-icon icon="plus"/> Add Requirements 
                                        </CButton>
                                    </CCol>
                                    <CCol lg="3">
                                        <CButton    
                                            block
                                            size="sm"
                                            color="danger" 
                                            :disabled="action_control"
                                            @click="archiveSignatory()"
                                            v-if="$store.getters['can']('archive-document-template-signatory')"
                                        >
                                            <font-awesome-icon icon="user-minus"/> Archive Signatory
                                        </CButton>
                                    </CCol>
                                    <CCol lg="3">
                                        <CButton 
                                            block
                                            size="sm"
                                            color="warning" 
                                            @click="replaceSignatorySearchUser()"
                                            :disabled="action_control"
                                            v-if="$store.getters['can']('replace-document-template-signatory')"
                                        >
                                            <font-awesome-icon icon="user-slash"/> Replace Signatory
                                        </CButton> 
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Inactive">
                                <template slot="title">
                                    Inactive Template/s <CBadge color="danger">( {{ [...new Set(inactive_templates.map((item) => item.name))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="inactive_templates"
                                            :fields="$store.getters['can']('restore-document-template-signatory') ? inactive_templates_field : inactive_templates_without_permission"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            @row-clicked="rowClickedInactive"
                                            sorter
                                            pagination
                                            :loading="this.table_loading"
                                        >
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner
                                                        style="width:4rem;height:4rem;"
                                                        color="info"
                                                    />
                                                </center>
                                            </template>
                                            
                                            <template #checkbox-header>
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success" v-model="isCheckedAllInactive"
                                                    @change="selectAllInactive($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>

                                            <template #checkbox="{item}">
                                                <td>
                                                    <center>
                                                        <p-check 
                                                            class="p-icon p-bigger" 
                                                            color="success" 
                                                            :value="item.id"
                                                            v-model="selected_templates_inactive"
                                                        >
                                                            <font-awesome-icon class="icon" icon="check"/>
                                                            
                                                        </p-check> 
                                                        &nbsp;
                                                    </center>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton 
                                            block
                                            size="sm"
                                            color="success" 
                                            @click="restoreSignatory()"
                                            :disabled="action_control_inactive"
                                            v-if="$store.getters['can']('restore-document-template-signatory')"
                                        >
                                            <font-awesome-icon icon="trash-restore"/> Restore Signatory
                                        </CButton>
                                    </CCol>
                                    
                                    <CCol lg="9">
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>

                        
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <UsersModal ref="usersModal" @selectedUser="userSelected($event)"/>
        <CModal
            :show.sync="replace_signatory_modal"
            color="primary"
            size="lg"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="user-slash"/> Replace Signatory </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="backToShowUser()"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <h6>Selected User:</h6> <h5>{{ selected_signatory.name }}</h5>
                    <br>
                    <CRow>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <b>Requirement</b>
                        </CCol>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <b>Description</b>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <CInput 
                                placeholder="Amount must be above 10,000.00?" 
                                v-tocapitalize
                                v-model="manually.requirement"
                                autocomplete="off">
                            </CInput>
                        </CCol>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <CTextarea
                                v-model="manually.description"
                                class="grp-text-area-cust"
                                placeholder="Brief description..."
                                autocomplete="off"  
                                v-tocapitalize
                            />
                        </CCol>
                        <CCol xl="2" lg="2" md="2" sm="2">
                            <CButton 
                                :disabled="!manually.requirement || !manually.description"
                                @click="addRequirementManually()"
                                block
                                color="primary">
                                <font-awesome-icon icon="plus"/>
                            </CButton>
                        </CCol>
                    </CRow>
                    <br>
                    
                    <CRow>
                        <CCol lg="12">
                            <CButton 
                                title="Copy requirement(s)"
                                size="sm"
                                @click="copyRequirements()"
                                color="info">
                                <font-awesome-icon icon="copy"/> 
                            </CButton>
                            &nbsp;
                            Copy <i><b>unique</b></i> requirement(s) of <b>{{ $route.query.name }}</b> from the selected template.
                        </CCol>
                    </CRow>
                    <hr>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :items="selected_signatory.requirements"
                                :fields="selected_signatory.requirement_field"
                                border
                                sorter
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <CButton 
                                            @click="removeRequirement(item, index)"
                                            color="danger" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="times"/> Remove
                                        </CButton>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
            <template #footer>
                <CButton color="secondary" @click="backToShowUser()">
                    <font-awesome-icon class="icon" icon="arrow-left"/> Back
                </CButton>
                <CButton color="primary" 
                    @click="continueReplace()"
                >
                    <font-awesome-icon 
                        class="icon" icon="fast-forward"/> 
                        Continue Replace
                </CButton>
            </template>
        </CModal>
        <CModal
            :show.sync="show_requirements_modal"
            color="primary"
            :closeOnBackdrop="false"
            size="lg"
        >
            <template #header>
                <h5> <font-awesome-icon icon="user"/> {{my_template.purpose}} requirement(s) for {{ my_template.name }} </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="show_requirements_modal = false;"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <CButton color="dark" @click="show_requirements_modal = false">
                    <font-awesome-icon class="icon" icon="window-close"/> Close
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>

                    <CDataTable
                        :items="my_template.requirements"
                        :fields="my_template.requirements_field"
                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                        :items-per-page="10"
                        border
                        items-per-page-select
                        sorter
                        pagination
                    >
                    </CDataTable>
                </CCardBody>
            </template>
        </CModal>
        <CModal
            :show.sync="add_requirements_modal"
            color="primary"
            size="lg"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="plus"/> Add Requirement(s) </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="add_requirements_modal = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <h6>Selected template(s):</h6> 
                    <ul>
                        <li v-for="(template, index) in add_requirements.templates" v-bind:key="index">
                            <span style="font-weight: 700; font-size: larger;">{{template.name}}</span> as <small><i>{{template.purpose}}</i></small>.
                        </li>
                    </ul>
                    <hr>
                    <CRow>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <b>Requirement</b>
                        </CCol>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <b>Description</b>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <CInput 
                                placeholder="Amount must be above 10,000.00?" 
                                v-tocapitalize
                                v-model="add_requirements.reqs"
                                autocomplete="off">
                            </CInput>
                        </CCol>
                        <CCol xl="5" lg="5" md="5" sm="5">
                            <CTextarea
                                v-model="add_requirements.desc"
                                class="grp-text-area-cust"
                                placeholder="Brief description..."
                                autocomplete="off"  
                                v-tocapitalize
                            />
                        </CCol>
                        <CCol xl="2" lg="2" md="2" sm="2">
                            <CButton 
                                :disabled="!add_requirements.reqs || !add_requirements.desc"
                                @click="addRequirementToArray()"
                                block
                                color="primary">
                                <font-awesome-icon icon="plus"/>
                            </CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :items="add_requirements.list"
                                :fields="add_requirements.list_field"
                                border
                                sorter
                                pagination
                            >
                                <template #action="{item, index}">
                                    <td>
                                        <CButton 
                                            @click="removeRequirementToArray(item, index)"
                                            color="danger" 
                                            shape="pill" 
                                            size="sm"
                                        >
                                            <font-awesome-icon icon="times"/> Remove
                                        </CButton>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
            <template #footer>
                <CButton color="secondary" @click="add_requirements_modal = false">
                    <font-awesome-icon class="icon" icon="window-close"/> Close
                </CButton>
                <CButton color="primary" 
                    @click="saveAddedRequirements()"
                >
                      <font-awesome-icon icon="save"/> Save
                </CButton>
            </template>
        </CModal>
        <CModal
            :show.sync="remove_requirements_modal"
            color="primary"
            size="lg"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="times"/> Remove Requirement(s) </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="remove_requirements_modal = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <h6>Selected template(s):</h6> 
                    <ul>
                        <li v-for="(template, index) in remove_requirements.templates" v-bind:key="index">
                            <span style="font-weight: 700; font-size: larger;">{{template.name}}</span> as <small><i>{{template.purpose}}</i></small>.
                        </li>
                    </ul>
                    <hr>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :items="remove_requirements.list"
                                :fields="remove_requirements.list_field"
                                border
                                sorter
                                pagination
                            >
                            <template slot="no-items-view" v-if="remove_requirements.list_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>
                                <template #action="{item, index}">
                                    <td>
                                        <CButton 
                                            @click="deleteRequirement(item, index)"
                                            color="danger" 
                                            shape="pill" 
                                            size="sm"
                                            :disabled="remove_requirements.removing ? (item.requirement == remove_requirements.selected_requirement ? true : false) : false"
                                        >
                                        <font-awesome-icon :icon="remove_requirements.removing ? (item.requirement == remove_requirements.selected_requirement ? 'circle-notch' : 'times') : 'times'" 
                                                                :pulse="remove_requirements.removing ? (item.requirement == remove_requirements.selected_requirement ? true : false) : false"/> Remove
                                        </CButton>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
            <template #footer>
               <hr>
            </template>
        </CModal>
    </div>
</template>

<script>
import UsersModal from '../../modals/UsersModal'
export default {
    name: 'DocumentTemplateSignatoryEdit',
    components: {
        UsersModal,
    },
    data() {
        return {
            replace_signatory_modal: false,
            show_requirements_modal: false,
            add_requirements_modal: false,
            remove_requirements_modal: false,
            templates:[ 
                // {name: "Template 1", purpose: "Approver", order: "2", requirements: ["req1", "req2", "req3", ]},
                // {name: "Template 2", purpose: "Creator", order: "1", requirements: ["req1", "req2", "req3", ]},
                // {name: "Template 2", purpose: "Approver", order: "2", requirements: ["req1", "req2", "req3", ]},
                // {name: "Template 3", purpose: "Approver", order: "4", requirements: ["req1", "req2", "req3", ]},
            ],
            inactive_templates_field: [
                { key: 'checkbox', label: '', _classes: 'th-fixed-width'},
                { key: 'name', label: 'Template Name'},
                { key: 'purpose'},
                { key: 'order'},
            ],

            inactive_templates_without_permission: [
                { key: 'name', label: 'Template Name'},
                { key: 'purpose'},
                { key: 'order'},
            ],

            number_of_templates_belong: 0,
            table_loading: true,
            selected_templates:[],
            selected_templates_inactive:[],
            signatory: {
                user_id: null,
            },

            my_template: {
                purpose: null,
                name: null,
                requirements: [],
                requirements_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                ],
            },

            add_requirements: {
                templates: [],
                reqs: null,
                desc: null,
                list: [],
                list_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ],
            },

            
            remove_requirements: {
                templates: [],
                list: [],
                list_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ],
                list_loading: true,
                removing:false,
                selected_requirement: null,
            },

            selected_signatory: {
                user_id: null,
                name: null,
                requirements: [],
                requirement_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ],
            },

            manually: {
                requirement: null,
                description: null,
            },
            isCheckedAll : false,
            isCheckedAllInactive : false,
            active_tab : 0,
            signed_doc_num : 0,
        }
    },
    created() {
        this.signatory.user_id = this.$route.params.id
        this.getMyTemplates();
        this.getSignedDocumentTemplate();
        
    },
    computed: {
        action_control: function () {
            return this.selected_templates.length > 0 ? false : true;
        },
        
        action_control_inactive: function () {
            return this.selected_templates_inactive.length > 0 ? false : true;
        },

        templates_field () {
            
            let custome_fields_with_permission = [
                { key: 'checkbox', label: '', _classes: 'th-fixed-width'},
                { key: 'name', label: 'Template Name'},
                { key: 'purpose'},
                { key: 'order'},
                { key: 'requirements'},
            ];
            let custom_fields= [
                { key: 'name', label: 'Template Name'},
                { key: 'purpose'},
                { key: 'order'},
                { key: 'requirements'},
                // { key: 'status' },
            ]
            // if(this.$store.getters.can('view-document-template-signatory')) {
            //     custom_fields.push({ key: 'Action', sorter: false})
            // }

            if(this.$store.getters['can']('archive-document-template-signatory') 
            || this.$store.getters['can']('replace-document-template-signatory') || 
            this.$store.getters['can']('add-document-template-requirements'
            || this.$store.getters['can']('delete document-template-requirements'))) {
                return custome_fields_with_permission;
            } else {
                return custom_fields;
            }
        },

        active_templates: function (){
            return this.templates.filter((item) => item.is_active == 1).sort((a, b) => a.name.localeCompare(b.name));
        },

        inactive_templates: function (){
            return this.templates.filter((item) => item.is_active == 0).sort((a, b) => a.name.localeCompare(b.name));
        },

    },
    methods: {
        changeTab : function (val) {
            if(val == 1) {
                this.selected_templates = [];
                this.isCheckedAll = false;
            } else {
                this.selected_templates_inactive = [];
                this.isCheckedAllInactive = false;
            }
            this.active_tab = val;
        },
        restoreSignatory: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to restore <b>${this.$route.query.name}</b> to the selected templates.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let selected_lines = this.selected_templates_inactive.map(item => this.paramEncoder(item));
                    let data = {
                        selected_lines: selected_lines
                    }
                    this.$Progress.start()
                    return axios.post('/drs/document-template-signatory/restore-signatory', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.$route.query.name}</b> has been restored to the selected template(s).`,
                            })
                            this.getMyTemplates();
                            this.onload();
                            this.selected_templates_inactive = [];
                            this.isCheckedAllInactive = false;
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },  
        rowClicked: function(item, index, column, e) {
            if(this.$store.getters['can']('archive-document-template-signatory') 
            || this.$store.getters['can']('replace-document-template-signatory') || 
            this.$store.getters['can']('add-document-template-requirements'
            || this.$store.getters['can']('delete document-template-requirements'))) {
                if(column != 'checkbox' && column!= 'requirements') {
                    let i = this.selected_templates.indexOf(item.id);
                    if (i !== -1) {
                        if(column != 'checkbox') {
                            this.selected_templates.splice(i, 1)
                        } else {
                            if (this.selected_templates.length == 1) { 
                                this.isCheckedAll = false;
                                this.selected_templates = [];
                            }
                        }
                        
                        if(this.selected_templates.length < 1) {
                            this.isCheckedAll = false;
                        }
                    } else {
                        this.selected_templates.push(item.id)
                    }
                }
            }
        },
        rowClickedInactive: function(item, index, column, e) {
           if(this.$store.getters['can']('restore-document-template-signatory') ) {
                if(column != 'checkbox') {
                    let i = this.selected_templates_inactive.indexOf(item.id);
                    if (i !== -1) {
                        if(column != 'checkbox') {
                            this.selected_templates_inactive.splice(i, 1)
                        } else {
                            if (this.selected_templates_inactive.length == 1) { 
                                this.isCheckedAllInactive = false;
                                this.selected_templates_inactive = [];
                            }
                        }

                        if(this.selected_templates_inactive.length < 1) {
                            this.isCheckedAllInactive = false;
                        }
                    
                    } else {
                        this.selected_templates_inactive.push(item.id)
                    }
                }
           }
        },
        selectAllActive : function () {
            if(!this.isCheckedAll) {
                this.selected_templates = [];
            } else {
                this.selected_templates = this.active_templates.map(item => item.id);
            }
        },
        selectAllInactive : function () {
            if(!this.isCheckedAllInactive) {
                this.selected_templates_inactive = [];
            } else {
                this.selected_templates_inactive = this.inactive_templates.map(item => item.id);
            }
        },
        onload : function () {
            this.templates = []    
            this.selected_templates = []  
        },
        getSignedDocumentTemplate : function () {
            let decode_data = decodeURIComponent(this.signatory.user_id);
            let user_id = atob(decode_data);
            this.$Progress.start()
            axios.get('/drs/document-template-signatory/list', {validateStatus: () => true})
                .then( response => {
                      if(response.status == 200) {
                        let list = response.data.data;
                        let data = list.filter((x) => x.id == user_id);
                        this.signed_doc_num = data[0].document_signed_count;
                        this.table_loading = false;
                        this.$Progress.finish()
                    }
                });
        },
        getMyTemplates: function () {
            this.$Progress.start()     
            axios.get('/drs/document-template-signatory/' + this.signatory.user_id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.table_loading = false;
                    for (let i = 0; i < response.data.data.length; i++) {
                        const element = response.data.data[i];
                        let _row = {
                            id: element.id,
                            name: element.document_templates.name,
                            purpose: element.purpose,
                            order: element.order,
                            requirements: element.document_signature_requirements,
                            is_active: parseInt(element.is_active)
                        }
                        this.templates.push(_row);
                    }
                    const active_only = this.templates.filter((item) => item.is_active == 1);
                    const unique = [...new Set(active_only.map((item) => item.name))];
                    this.number_of_templates_belong = unique.length;
                    this.$Progress.finish()            
                }
            })

        },
        showMyRequirements: function (item) {
            this.my_template.purpose = item.purpose;
            this.my_template.name = item.name;
            this.my_template.requirements = item.requirements
            this.show_requirements_modal = true
        },
        archiveSignatory: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive <b>${this.$route.query.name}</b> to the selected templates.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let selected_lines = this.selected_templates.map(item => this.paramEncoder(item));
                    let data = {
                        selected_lines: selected_lines
                    }
                    this.$Progress.start()
                    return axios.post('/drs/document-template-signatory/archive-signatory', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.$route.query.name}</b> has been archived to the selected template(s).`,
                            })
                            this.getMyTemplates();
                            this.onload();
                            this.selected_templates = [];
                            this.isCheckedAll = false;
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        replaceSignatorySearchUser: function () {
            this.$refs.usersModal.users_modal = true;
            this.$emit('show_users_modal');
            
        },
        userSelected: function (object) {
            let decoded_uri=  decodeURIComponent(this.$route.params.id);
            let user_id = atob(decoded_uri);
            if(object.id == user_id) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Selecting the same signatory is not allowed.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            this.selected_signatory.user_id = object.id;
            this.selected_signatory.name = object.name;
            this.replace_signatory_modal = true;
        },
        clearSelectedSignatory: function () {
            return {
                user_id: null,
                name: null,
                requirements: [],
                requirement_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ]
            }
        },
        backToShowUser: function () {
            this.selected_signatory = this.clearSelectedSignatory();
            this.replace_signatory_modal = false;
            this.$refs.usersModal.users_modal = true;
            this.$emit('show_users_modal');
        },
        addRequirementManually: function () {
            if(this.manually.requirement && this.manually.description) {
                if(this.selected_signatory.requirements.filter(x => x.requirement.toLowerCase() == this.manually.requirement.toLowerCase()).length > 0) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: `${this.manually.requirement} already exists!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }

                this.selected_signatory.requirements.push({
                    requirement: this.manually.requirement.trim(),
                    description: this.manually.description.trim() 
                })
                this.manually.requirement = null;
                this.manually.description = null;
            }
        },
        removeRequirement: function (item, index) {
            this.selected_signatory.requirements.splice(index, 1)
            return this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: `${item.requirement} has been removed.`,
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
        },
        copyRequirements: function () {
            let ids = this.selected_templates.map(item => this.paramEncoder(item));
            let data = {
                document_template_signature_id: ids
            }
            this.$Progress.start()
            axios.post('/drs/document-template-signatory/replacement-copy-requirements', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200 ) {
                    this.selected_signatory.requirements = []
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `Requirement(s) copied!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    
                    this.$Progress.finish()
                    this.selected_signatory.requirements = response.data.data
                }
            })
        },
        continueReplace: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to replace <b>${this.$route.query.name}</b> as signatory to the selected templates.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let encoded_selected_lines = this.selected_templates.map(item => this.paramEncoder(item));
                    // Data here
                    let data = {
                            selected_lines: encoded_selected_lines,
                            old_user: {
                                name: this.$route.query.name,
                            },
                            new_user: {
                                id: this.paramEncoder(this.selected_signatory.user_id),
                                name: this.selected_signatory.name,
                            },
                            requirements: this.selected_signatory.requirements.length > 0 ? this.selected_signatory.requirements : []
                        }
                    this.$Progress.start()
                    return axios.post('/drs/document-template-signatory/replace-signatory', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.selected_signatory.name}</b> has successfully replaced <i>${this.$route.query.name}</i> as signatory to the selected template(s).`,
                            })
                            this.replace_signatory_modal = false;
                            this.isCheckedAll = false;
                            this.getMyTemplates();
                            this.onload();                   
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        freshAddRequirements: function  () {
            return {
                templates: [],
                reqs: null,
                desc: null,
                list: [],
                list_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ],
            }
        },
        freshRemoveRequirements: function () {
            return  {
                templates: [],
                list: [],
                list_field: [
                    {key: 'requirement'},
                    {key: 'description'},
                    {key: 'action'},
                ],
                list_loading: true,
                removing:false,
                selected_requirement: null,
            }
        },
        openAddRequirementsModal: function () {
            this.add_requirements_modal = true;
            this.add_requirements.templates = this.templates.filter(x => this.selected_templates.includes(x.id))
            this.isCheckedAll = false;
        },
        addRequirementToArray: function () {
            if(this.add_requirements.reqs && this.add_requirements.desc) {
                if(this.add_requirements.list.filter(x => x.requirement.toLowerCase() == this.add_requirements.reqs.toLowerCase()).length > 0) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: `${this.add_requirements.reqs} already exists!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }

                this.add_requirements.list.push({
                    requirement: this.add_requirements.reqs.trim(),
                    description: this.add_requirements.desc.trim() 
                })
                this.add_requirements.reqs = null;
                this.add_requirements.desc = null;
            }
        },
        removeRequirementToArray: function (item, index) {
            this.add_requirements.list.splice(index, 1)
            return this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: `${item.requirement} has been removed.`,
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
        },
        saveAddedRequirements: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to add requirements to the selected templates.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    // Data here
                    let ids = this.selected_templates.map(item => this.paramEncoder(item));
                    let data = {
                        document_template_id: ids,
                        requirements: this.add_requirements.list
                    }
                    this.$Progress.start()
                    return axios.post('/drs/document-template-signatory/add-requirements', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `Requirement(s) added to the selected template(s).`,
                            })
                            
                            this.add_requirements_modal = false;
                            this.getMyTemplates();
                            this.onload();
                            this.add_requirements = this.freshAddRequirements();
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        openRemoveRequirementsModal: function () {
            this.remove_requirements_modal = true;
            this.remove_requirements.list = [];
            this.remove_requirements.templates = this.templates.filter(x => this.selected_templates.includes(x.id))
            this.getRequirement();
            this.isCheckedAll = false;
        },
        getRequirement: function () {
            let ids = this.selected_templates.map(item => this.paramEncoder(item));
            let data = {
                document_template_signature_id: ids
            }
            this.$Progress.start()
            this.remove_requirements.list_loading = true;
            axios.post('/drs/document-template-signatory/get-requirements', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200 ) {
                    this.$Progress.finish()
                    this.remove_requirements.list = response.data.data
                }
                this.remove_requirements.list_loading = false;
               
            })
        },
        deleteRequirement: function (item, index) {
            this.remove_requirements.removing = true;
            this.remove_requirements.selected_requirement = item.requirement;
            // Data here
            let ids = this.selected_templates.map(item => this.paramEncoder(item));
            let data = {
                document_template_id: ids,
                requirement: item.requirement
            }
            this.$Progress.start()
            axios.post('/drs/document-template-signatory/delete-requirements', data, {validateStatus: () => true})
            .then(response => {
                if ( response.status == 200 ) {
                    this.$Progress.finish()
                    
                    this.remove_requirements.removing = false;
                    this.remove_requirements.list.splice(index, 1)
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.requirement} has been removed.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        }
    },
    watch: {
        add_requirements_modal: function (value) {
            if(!value) this.add_requirements = this.freshAddRequirements();
        },
        remove_requirements_modal: function (value) {
            if(!value) {
                this.onload();
                this.getMyTemplates();
                this.remove_requirements = this.freshRemoveRequirements();
            } 
        },
        selected_templates :function (value) {
            if(value) {
                if (this.selected_templates.length < 1) {
                    this.isCheckedAll = false;
                }
            }
        },
        
        selected_templates_inactive : function(value) {
            if(value) {
                if(this.selected_templates_inactive.length < 1) {
                    this.isCheckedAllInactive = false;
                }
            }
        }
    }




}
</script>

<style>
    .th-fixed-width {
        width: 50px !important;
    }

    .grp-text-area-cust {
            margin-bottom: 5px !important;
    }

    .grp-text-area-cust textarea {
        resize: none;
        height: 35px;
    }
</style>